<script setup lang="ts">
import '../styles/theme-overrides.css';
import { useClientTimeZone } from '../utils/date';
import { $dt } from '@primevue/themes';

defineProps<{
  theme?: 'getmo' | 'pondopinoy';
  disableReleaseTracking?: boolean;
}>();

const timeZone = useClientTimeZone();

if (import.meta.client) {
  const nuxt = useNuxtApp();
  const toast = useToast();
  nuxt.hooks.hook('vue:error', () => {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Something went wrong',
      life: 3000,
    });
  });

  timeZone.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
}
</script>

<template>
  <slot />

  <Toast />
  <ConfirmDialog />
  <ConfirmPopup group="popup" />
  <NuxtLoadingIndicator :color="$dt('primary.500').value" />
  <HydrationSignal />
  <ReleaseTracker v-if="!disableReleaseTracking && $config.public.env !== 'dev'" />

  <LazyThemePondopinoy v-if="theme === 'pondopinoy'" />
  <LazyThemeGetmo v-else />
</template>

<!-- eslint-disable vue/enforce-style-attribute -->
<style>
@import 'normalize.css' layer(normalize);

html {
  height: 1px;
  min-height: 100%;
}

/* stylelint-disable-next-line selector-id-pattern */
body,
#__nuxt {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font: var(--font-14);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text-default);
}

* {
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

kbd {
  padding: 0.1em 0.25em;
  word-spacing: -0.25em;
  border: 1px solid var(--color-border);
  border-radius: 0.25em;
}
</style>
