import * as Sentry from '@getmo/common/vendor/@sentry/vue';
import { createFetch, FetchError } from '@getmo/common/vendor/ofetch';

export default defineNuxtPlugin((nuxtApp) => {
  const {
    app: { baseURL },
    public: { env, sentry },
  } = useRuntimeConfig();

  if (env === 'dev') return;

  if (!sentry.dsn) {
    log.warn('Sentry DSN not set, skipping Sentry initialization');
    return;
  }

  const router = useRouter();

  // Remove nuxt error handler to avoid error page showing on any exception. Nuxt resets this error handler if sentry is not used.
  // https://github.com/nuxt/nuxt/blob/main/packages/nuxt/src/app/entry.ts#L85
  nuxtApp.vueApp.config.errorHandler = undefined;

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: env,
    tracesSampleRate: 1,
    normalizeDepth: 6,
    allowUrls: [window.location.origin],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.extraErrorDataIntegration({ depth: 5 }),
      Sentry.reportingObserverIntegration(),
    ],
  });

  Sentry.addEventProcessor((ev, hint) => {
    let ex = hint.originalException;
    while (ex instanceof Error) {
      if (
        ex instanceof FetchError &&
        typeof ex.request === 'string' &&
        ex.request.startsWith('/') &&
        ex.options?.baseURL === baseURL
      ) {
        ev.level = 'warning';
        break;
      }
      ex = ex.cause;
    }

    return ev;
  });

  Sentry.addEventProcessor((ev, hint) => {
    let ex = hint.originalException;
    while (ex instanceof Error) {
      if ('fingerprint' in ex && ex.fingerprint) {
        ev.fingerprint = ex.fingerprint as string[];
        break;
      }
      ex = ex.cause;
    }

    ex = hint.originalException;
    while (ex instanceof Error) {
      if ('level' in ex && ex.level) {
        ev.level = ex.level as Sentry.SeverityLevel;
        break;
      }
      ex = ex.cause;
    }

    return ev;
  });

  const app = nuxtApp as unknown as { sentryScope: Sentry.Scope };
  app.sentryScope = Sentry.getCurrentScope();

  globalThis.$fetch = createFetch({
    defaults: {
      baseURL,
      onRequestError({ error }) {
        if (error instanceof TypeError) {
          (error as unknown as { fingerprint: string[] }).fingerprint = ['network-error'];
          (error as unknown as { level: Sentry.SeverityLevel }).level = 'warning';
        }
      },
    },
  }) as typeof globalThis.$fetch;
});
