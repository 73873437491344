<script setup lang="ts">
import { useSocket } from '../utils/socketio';
import { Type } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

const socket = useSocket();
const schema = Type.Object({
  version: Type.String(),
});

const toast = useToast();
const router = useRouter();

let lastVersion: undefined | string = useRuntimeConfig().app.buildId;
socket.on('currentRelease', (data) => {
  const message = Value.Decode(schema, Value.Convert(schema, data));

  if (!lastVersion) {
    lastVersion = message.version;
    return;
  }

  if (lastVersion !== message.version) {
    lastVersion = message.version;
    toast.add({
      summary: 'New version of the application was released',
      detail: 'The page needs to be reloaded, otherwise the application may not work correctly.',
      severity: 'warn',
      closable: true,
    });
    // Disable SPA navigation, so that page is
    // reloaded next time user navigates anywhere
    router.beforeEach((to) => {
      window.location.href = to.fullPath;
      return false;
    });
  }
});
</script>

<template>
  <slot />
</template>
