<script setup lang="ts">
const url = useRequestURL();

const theme = url.host.includes('pondopinoy') ? 'pondopinoy' : 'getmo';
provideBranding(theme);

if (theme === 'pondopinoy') {
  useHead({
    link: [{ rel: 'icon', type: 'image/png', href: '/favicon-pondopinoy.png' }],
  });
}
</script>

<template>
  <GetmoApp :theme="theme">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <LazyThemeGetmo v-if="theme === 'getmo'" />
    <LazyThemePondopinoy v-else />
  </GetmoApp>
</template>
