import { default as indexO8TKc79xRFMeta } from "/app/app/pages/[[onboardingId]]/dashboard/calendar/index.vue?macro=true";
import { default as indexMjN9ELfwU6Meta } from "/app/app/pages/[[onboardingId]]/dashboard/index.vue?macro=true";
import { default as indexoYkmcpY2BgMeta } from "/app/app/pages/[[onboardingId]]/disbursement/index.vue?macro=true";
import { default as indexexeh3fHLo1Meta } from "/app/app/pages/[[onboardingId]]/stores/index.vue?macro=true";
import { default as _91_91onboardingId_93_93Y1EjrySwzyMeta } from "/app/app/pages/[[onboardingId]].vue?macro=true";
import { default as agreementPdf6R3YQkautpMeta } from "/app/app/pages/agreementPdf.vue?macro=true";
import { default as indexM6mJRhIbwTMeta } from "/app/app/pages/index.vue?macro=true";
import { default as indexFvNcnTBBTKMeta } from "/app/app/pages/login/index.vue?macro=true";
import { default as callbackTMeEqKAllQMeta } from "/app/app/pages/stores/callback.vue?macro=true";
export default [
  {
    name: "onboardingId",
    path: "/:onboardingId?",
    meta: _91_91onboardingId_93_93Y1EjrySwzyMeta || {},
    component: () => import("/app/app/pages/[[onboardingId]].vue"),
    children: [
  {
    name: "onboardingId-dashboard-calendar",
    path: "dashboard/calendar",
    component: () => import("/app/app/pages/[[onboardingId]]/dashboard/calendar/index.vue")
  },
  {
    name: "onboardingId-dashboard",
    path: "dashboard",
    component: () => import("/app/app/pages/[[onboardingId]]/dashboard/index.vue")
  },
  {
    name: "onboardingId-disbursement",
    path: "disbursement",
    component: () => import("/app/app/pages/[[onboardingId]]/disbursement/index.vue")
  },
  {
    name: "onboardingId-stores",
    path: "stores",
    component: () => import("/app/app/pages/[[onboardingId]]/stores/index.vue")
  }
]
  },
  {
    name: "agreementPdf",
    path: "/agreementPdf",
    meta: agreementPdf6R3YQkautpMeta || {},
    component: () => import("/app/app/pages/agreementPdf.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexM6mJRhIbwTMeta || {},
    component: () => import("/app/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexFvNcnTBBTKMeta || {},
    component: () => import("/app/app/pages/login/index.vue")
  },
  {
    name: "stores-callback",
    path: "/stores/callback",
    component: () => import("/app/app/pages/stores/callback.vue")
  }
]