import { io } from 'socket.io-client';

const socket = io({
  // Otherwise socket seems to open before app is fully
  // hydrated, and some messages may be missed
  autoConnect: false,
  path: '/_ws',
  transports: ['websocket'],
});

export const useSocket = () => {
  if (!socket.connected) {
    socket.open();
  }

  return socket;
};
