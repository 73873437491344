import { type TZDate } from '@date-fns/tz';
import { DF } from '@getmo/common/vendor/date-fns';
import { $Enums } from '@prisma/client';

export const TIMEZONES: Record<$Enums.Region, string> = {
  [$Enums.Region.id]: 'Asia/Jakarta',
  [$Enums.Region.ph]: 'Asia/Manila',
};

export const formatDateShort = (d: Date) => DF.format(d, 'd MMM');
export const formatDateFull = (d: Date) => DF.format(d, 'd MMMM yyyy');
export const dateFullAltFormat = 'MMM d, yyyy';

export const useClientTimeZone = () => useCookie('timezone', { maxAge: 365 * 24 * 60 * 60 });

export const createLocalDateWithSameTime = (d: TZDate) =>
  new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
    d.getMilliseconds(),
  );
