import GetmoLogo from '../assets/icons/logo.svg';
import PondopinoyLogo from '../assets/icons/pondoPinoy.png';
import { $Enums } from '@prisma/client';

const key = Symbol('branding') as InjectionKey<'getmo' | 'pondopinoy'>;
export const provideBranding = (theme: 'getmo' | 'pondopinoy') => provide(key, theme);

export default () => {
  const theme = inject(key, 'getmo');

  return theme === 'pondopinoy'
    ? {
        region: $Enums.Region.ph,
        logo: { component: () => h('img', { alt: 'logo', src: PondopinoyLogo }) },
        illustrationHueRotate: '-105deg',
        phonePrefix: '63',
        phoneMask: `+63 999 999 9999` as const,
        phonePlaceholder: `+63 000 000 0000`,
      }
    : {
        region: $Enums.Region.id,
        logo: { component: () => h('img', { alt: 'logo', src: GetmoLogo }) },
        illustrationHueRotate: '0',
        phonePrefix: '62',
        phoneMask: `+62 999 9999 99?999` as const,
        phonePlaceholder: `+62 000 0000 0000`,
      };
};
