<script setup lang="ts">
// Used in e2e tests to wait for page hydration

const hydrated = ref(false);
onMounted(() => {
  hydrated.value = true;
});
</script>

<template>
  <span id="hydration-signal" :class="hydrated ? 'hydrated' : ''" />
</template>
